import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
/*
import Layout from "./pages/Layout";

import NoPage from "./pages/NoPage";
*/
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        Swift Computer Pvt Ltd
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </header>
      
      <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<div>Others</div>} />
      
      </Routes>
      <footer className="App-header">
        Copyright@2023
      </footer>
    </BrowserRouter>
    </div>
  );
}

export default App;
